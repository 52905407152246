import React from 'react';
import MemberLayout from '../components/layout/member-layout';
import SEO from '../components/layout/seo';
import CustomerUpdate from '../components/customer/customer-update';
import { useAuthUserCheckOrError } from '../libs/handleHttpError';

const CustomerUpdatePage = (changes) => {
  useAuthUserCheckOrError();

  return (
    <MemberLayout>
      <SEO title="Update Account Profile" />
      <CustomerUpdate />
    </MemberLayout>
  );
};

export default CustomerUpdatePage;
